import React from 'react'
import { renderAbsolutePositionedLoading } from '../../../components/app_suspense_container'
import { AppConfig, useAppConfig } from './hooks/use_app_config.hook'

export const AppConfigContext = React.createContext({
  appConfig: AppConfig.initNullInstance(),
  reloadAppConfig: () => Promise.resolve(),
})

export const AppConfigProvider = ({ children }) => {
  const { isAppConfigLoading, appConfig, reloadAppConfig } = useAppConfig()

  if (isAppConfigLoading) {
    return renderAbsolutePositionedLoading()
  }

  return (
    <AppConfigContext.Provider value={{ reloadAppConfig, appConfig }}>
      {children}
    </AppConfigContext.Provider>
  )
}

export const withAppConfig = (Component) => (props) => (
  <AppConfigContext.Consumer>
    {(contextValues) => (<Component {...props} {...contextValues} />)}
  </AppConfigContext.Consumer>
)
