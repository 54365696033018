import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Row, Col, Badge, Button } from 'reactstrap'
import { noop } from 'lodash/fp/util'

import AsyncResultSwitch from '@@src/components/async_result_switch'
import AsyncResult from '@@src/utils/async_result'
import EventSourceLocalisation from '@@src/api/presenters/event_source_localisation'
import LegendItem from '@@src/components/graphs/legend_item'
import ToggleGroupAlertStatusPrompt from '@@src/components/modals/toggle_group_alert_status_prompt'
import EventSourceLocalisationStore from '@@src/store/event_source_localisation'
import EventListTableContent from '@@src/analysis_path/pressure_analysis_path/event_list_table/event_list_table_content' // eslint-disable-line max-len
import {
  ORDER_ASC,
  ORDER_DESC,
} from '@@src/analysis_path/pressure_analysis_path/with_orderby_and_direction'
import {
  START_TIME_KEY,
  END_TIME_KEY,
  MIN_PRESSURE_KEY,
  MAX_PRESSURE_KEY,
  MEAN_PRESSURE_KEY,
  CPIS_KEY,
  SEVERITY_KEY,
} from '@@src/analysis_path/pressure_analysis_path/event_list_table/event_list_table'
import { compose } from 'redux'
import { CAN_VIEW_ANALYSIS } from '../../../_v2/contexts/user/consts/permissions'
import { withUser } from '../../../_v2/contexts/user/user.context'

import styles from './event_source_localisation_events.css'

class EventSourceLocalisationEvents extends React.PureComponent {
  static propTypes = {
    pagedPressureEventsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    legendItemsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    t: PropTypes.func.isRequired,
    eventSourceLocalisation: PropTypes.instanceOf(EventSourceLocalisation)
      .isRequired,
    networkAssets: PropTypes.array.isRequired,
    refetchEventSourceLocalisations: PropTypes.func.isRequired,
    onChangeHeatmapStatusSuccess: PropTypes.func.isRequired,
    onChangeHeatmapStatusCancel: PropTypes.func.isRequired,
    onClickViewEvents: PropTypes.func.isRequired,
    onMouseEnterEventRow: PropTypes.func.isRequired,
    onMouseLeaveEventRow: PropTypes.func.isRequired,
    setActiveExpandedEventId: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    selectedGroup: PropTypes.instanceOf(LegendItem),
    setOrderByAndDirection: PropTypes.func.isRequired,
    orderBy: PropTypes.oneOf([
      START_TIME_KEY,
      END_TIME_KEY,
      MIN_PRESSURE_KEY,
      MAX_PRESSURE_KEY,
      MEAN_PRESSURE_KEY,
      CPIS_KEY,
      SEVERITY_KEY,
    ]).isRequired,
    orderDirection: PropTypes.oneOf([ORDER_ASC, ORDER_DESC]).isRequired,
  }

  static defaultProps = {
    onChangeGroupHeatmapStatusCancel: noop,
    onChangeGroupHeatmapStatusSuccess: noop,
    onMouseEnterEventRow: noop,
    onMouseLeaveEventRow: noop,
    orderBy: START_TIME_KEY,
  }

  state = {
    openChangeHeatmapStatusModal: false,
  }

  render() {
    const { pagedPressureEventsResult } = this.props

    return (
      <AsyncResultSwitch
        result={pagedPressureEventsResult}
        renderSuccessResult={this.renderSuccess}
      />
    )
  }

  renderSuccess = ({ data: { pressureEvents } }) => {
    const {
      t,
      eventSourceLocalisation,
      selectedGroup,
      timezone,
      orderBy,
      orderDirection,
      setOrderByAndDirection,
      onMouseEnterEventRow,
      onMouseLeaveEventRow,
      legendItemsResult,
      pagedPressureEventsResult,
      authorizedUser,
    } = this.props
    const { openChangeHeatmapStatusModal } = this.state
    const onViewAllEventsClick =
      this.getHandleViewAllEventsClick(pressureEvents)

    return (
      <React.Fragment>
        <Row className="px-3" noGutters={true}>
          <Col className="d-flex align-items-center justify-content-start">
            <span className="font-weight-bold">
              <span name="event-localisation-display-name" className="mr-2">
                {eventSourceLocalisation.displayName}
              </span>
              {eventSourceLocalisation.isUnread() ? (
                <Badge name="event-localisation-new" color="danger">
                  {t('badge.new')}
                </Badge>
              ) : null}
            </span>
            <span name="event-localisation-count" className="ml-2">
              {t('text.based_on_event_count', {
                count: pressureEvents.length,
              })}
            </span>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            {authorizedUser.can(CAN_VIEW_ANALYSIS) && (
              <Button
                name="plot-link-button"
                className={'mr-2'}
                onClick={onViewAllEventsClick}
                color="primary"
              >
                <i className="far fa-chart-line pr-2" />
                {t('button.show_data')}
              </Button>
            )}
            <Button
              name="event-localisation-mark-as-read"
              color="light"
              onClick={this.handleMarkAsReadClick}
            >
              {eventSourceLocalisation.isUnread()
                ? t('button.mark_as_read')
                : t('button.mark_as_unread')}
            </Button>
          </Col>
        </Row>
        <Row className="px-3 pb-2 pt-3" noGutters={true}>
          <Col className={styles['event-localisation-tables-container']}>
            <EventListTableContent
              className={styles['event-list-table-content']}
              onMouseEnterEventRow={onMouseEnterEventRow}
              onMouseLeaveEventRow={onMouseLeaveEventRow}
              orderBy={orderBy}
              orderDirection={orderDirection}
              setOrderByAndDirection={setOrderByAndDirection}
              perPage={1}
              timezone={timezone}
              onClickViewEvents={onViewAllEventsClick}
              pagedPressureEventsResult={pagedPressureEventsResult}
              legendItemsResult={legendItemsResult}
            />
          </Col>
        </Row>
        <ToggleGroupAlertStatusPrompt
          groupId={selectedGroup.sourceId}
          heatmapIds={[eventSourceLocalisation.heatmapId]}
          isOpen={openChangeHeatmapStatusModal}
          newStatus={
            eventSourceLocalisation.isRead()
              ? EventSourceLocalisationStore.ALERT_STATUS_UNREAD
              : EventSourceLocalisationStore.ALERT_STATUS_READ
          }
          handleCancel={this.handleChangeGroupHeatmapStatusCancel}
          handleSuccess={this.handleChangeGroupHeatmapStatusSuccess}
        >
          {eventSourceLocalisation.isRead()
            ? t('text.confirm_unread_status', {
                groupName: selectedGroup.name,
                heatmapName: eventSourceLocalisation.displayName,
              })
            : t('text.confirm_read_status', {
                groupName: selectedGroup.name,
                heatmapName: eventSourceLocalisation.displayName,
              })}
        </ToggleGroupAlertStatusPrompt>
      </React.Fragment>
    )
  }

  handleMarkAsReadClick = () => {
    this.setState({ openChangeHeatmapStatusModal: true })
  }

  handleChangeGroupHeatmapStatusCancel = () => {
    this.setState({ openChangeHeatmapStatusModal: false })
    this.props.onChangeHeatmapStatusCancel()
  }

  handleChangeGroupHeatmapStatusSuccess = () => {
    this.setState({ openChangeHeatmapStatusModal: false })
    this.props.refetchEventSourceLocalisations()
    this.props.onChangeHeatmapStatusSuccess()
  }

  getHandleViewEventsClick = (heatmapEvents, eventAssets) => {
    const { onClickViewEvents, setActiveExpandedEventId } = this.props

    return (events) => {
      let activeEventId

      onClickViewEvents(heatmapEvents, eventAssets)

      if (Array.isArray(events)) {
        activeEventId = events.map(({ id }) => id)[0]
      } else {
        activeEventId = events.id
      }

      setActiveExpandedEventId(activeEventId, true)
    }
  }

  getHandleViewAllEventsClick = (events) => {
    const { networkAssets, selectedGroup } = this.props
    const eventNetworkAssets = networkAssets.filter((asset) => {
      return (
        events.findIndex(
          (event) => String(event.assetId) === String(asset.id)
        ) !== -1
      )
    })

    if (eventNetworkAssets.length === networkAssets.length) {
      // All network assets contributed to the event source localisation map
      return this.getHandleViewEventsClick(events, selectedGroup.source)
    } else {
      return this.getHandleViewEventsClick(events, eventNetworkAssets)
    }
  }
}

export default compose(
  withUser,
  withTranslation([
    'src/analysis_path/pressure_analysis_path/event_source_localisation/event_source_localisation_events',
  ])
)(EventSourceLocalisationEvents)
