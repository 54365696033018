import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Popup } from 'react-leaflet'
import { Button } from 'reactstrap'
import routes from '../../../../../../routes'
import { useTranslation } from 'react-i18next'
import IssuesSummaryBadgeComponent from '../../../../../components/issues/issues_summary_badge.component'
import { getSingleDeviceInfo } from '../../../../../api/devices.service'
import { CAN_VIEW_ANALYSIS } from '../../../../../contexts/user/consts/permissions'
import { useAuthorizedUser } from '../../../../../contexts/user/hooks/use_authorized_user.hook'
import { getLastContactKey, getNextContactKey } from '../../../../../domain/devices'
import './device_map_marker.styles.scss'
import { useApolloClient } from '@apollo/client'

const DeviceMapMarkerPopup = ({ deviceId, markerRef }) => {
  const { t } = useTranslation(['v2/common/text', 'v2/devices/common', 'v2/common/buttons'])
  const client = useApolloClient()
  const abortController = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [deviceDetails, setDeviceDetails] = useState(null)
  const [contactInfo, setContactInfo] = useState(null)
  const issues = deviceDetails && deviceDetails.getMostSeverityIssueWithOtherIssuesCount() || null

  const detailsPage = routes.managementDevicesDetailPath(+deviceId)
  const showDataLink = deviceDetails
    ? routes.analysisPressurePath({ d: [deviceDetails.getShowDataId()] })
    : null

  const onPopupOpen = () => {
    const markerHtmlElement = markerRef.current ? markerRef.current.leafletElement.getElement() : null

    setIsOpen(true)
    if (markerHtmlElement) {
      markerHtmlElement.className += ' active'
    }
  }
  const onPopupClose = () => {
    const markerHtmlElement = markerRef.current ? markerRef.current.leafletElement.getElement() : null

    setIsOpen(false)
    if (markerHtmlElement) {
      markerHtmlElement.className = markerHtmlElement.className.replace(' active', '')
    }
  }

  const fetchSingleDeviceInfo = () => {
    if (abortController.current instanceof AbortController) {
      abortController.current.abort()
    }

    abortController.current = new AbortController()

    setIsLoading(true)
    getSingleDeviceInfo({
      client,
      abortController: abortController.current,
      deviceId: +deviceId,
    })
      .then(result => {
        abortController.current = null
        setDeviceDetails(result.device)
        setIsLoading(false)
      })
  }

  // handling updating lables for next-previous contact
  useEffect(() => {
    const handleReCalculateContactInfo = () => {
      if(deviceDetails.getCanProvideContact() &&
          deviceDetails.getNextContactDate().getTime() - (new Date()).getTime() <= 1000) {
        fetchSingleDeviceInfo()
      }
      setContactInfo({
        previous: getLastContactKey(deviceDetails.getExpectedPrevContactDate()),
        next: getNextContactKey(deviceDetails.getNextContactDate()),
      })
    }

    if(isOpen && deviceDetails) {
      handleReCalculateContactInfo()
      let interval
      if(deviceDetails.getCanProvideContact()) {
        interval = setInterval(() => {
          handleReCalculateContactInfo()
        }, 1000)
      }
      return () => {
        if(interval) {
          clearInterval(interval)
        }
      }
    }
    return () => null
  }, [isOpen, deviceDetails])

  // handling fetching data for the popup
  useEffect(() => {
    if (isOpen && !deviceDetails) {
      fetchSingleDeviceInfo()
    } else if (!isOpen && abortController.current) {
      abortController.current.abort()
      abortController.current = null
    }
    return () => {
      if (abortController.current) {
        abortController.current.abort()
        abortController.current = null
      }
    }
  }, [isOpen])

  const {authorizedUser} = useAuthorizedUser()

  return (
    <Popup
      autoPan={false}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      className={'devices-popup'}
    >
      {isLoading && (<p>Loading...</p>)}
      {!isLoading && deviceDetails && (
        <>
          <div className="d-flex flex-row align-items-center">
            <h5 className="devices-serial-number">
              {deviceDetails.serialNumber}
            </h5>
            {!!issues && (
              <IssuesSummaryBadgeComponent
                issue={issues.mostSevereIssue ? issues.mostSevereIssue : null}
                errors={issues.errorsCount}
                warnings={issues.warningsCount}
                information={issues.informationCount}
              />
            )}
          </div>
          <div className="devices-contact-info">
            <span className="d-block">
              {
                `${t('v2/common/text:pressure_data_freshness')}:
                ${t(`v2/common/text:pressure_data_freshness_options.${deviceDetails.getDataFreshness()}`)}`
              }
            </span>
            {
              contactInfo &&
                <>
                  <span className="d-block">{
                    `${t('v2/devices/common:last_contact')}: 
                    ${t(`v2/devices/common:${contactInfo.previous.text}`, { ...contactInfo.previous.value })}`
                  }</span>
                  <span className="d-block">{
                    `${t('v2/devices/common:next_contact')}: 
                    ${t(`v2/devices/common:${contactInfo.next.text}`, { ...contactInfo.next.value })}`
                  }</span>
                </>
            }
          </div>
          <div className="mt-3 d-flex flex-row align-items-center">
            <Button size="sm" className="text-light mr-1" color="primary" tag={Link} to={detailsPage}>
              <i className="fa fa-info mr-2" />
              <span>{t('v2/common/buttons:show_details')}</span>
            </Button>
            {authorizedUser.can(CAN_VIEW_ANALYSIS) && (
              <Button size="sm" className="text-dark" color="light" tag={Link} to={showDataLink}>
                <i className="fa fa-chart-area mr-2" />
                <span>{t('v2/common/buttons:show_data')}</span>
              </Button>
            )}
          </div>
        </>
      )}
    </Popup>
  )
}

export default DeviceMapMarkerPopup
