import React, { useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Popup } from 'react-leaflet'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { compose } from 'redux'
import { mapAssetTypeToLogicalChannels } from '../../../../../../utils'
import { getNetworkAssetsForListItemCall } from '../../../../../api/network_assets.service'
import routes from '../../../../../../routes'

import './network_asset_map_marker.component.scss'
import IssuesSummaryBadgeComponent from '../../../../../components/issues/issues_summary_badge.component'
import NetworkAssetInstallationIconComponent
from '../../../../../components/network_asset/network_asset_installation_icon.component'
import { useApolloClient } from '@apollo/client'
import { CAN_VIEW_ANALYSIS } from '../../../../../contexts/user/consts/permissions'
import { useAuthorizedUser } from '../../../../../contexts/user/hooks/use_authorized_user.hook'

const NetworkAssetMapPopupComponent = ({ networkAsset, markerRef, t }) => {
  const client = useApolloClient()
  const abortController = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [networkAssetDetails, setNetworkAssetDetails] = useState(null)
  const issues = networkAssetDetails && networkAssetDetails.getMostSeverityIssueWithOtherIssuesCount() || null
  const lastRecordedDataTime = networkAssetDetails
    ? t(`v2/common/text:pressure_data_freshness_options.${networkAssetDetails.getPressureDataFreshnessKey()}`)
    : networkAssetDetails

  const detailsPage = routes.networkAssetsDetailPath(networkAsset.assetId)
  const {authorizedUser} = useAuthorizedUser()
  const showDataLink = networkAssetDetails
    ? routes.analysisPressurePath({ d: [networkAssetDetails.getShowDataId()] })
    : null

  const onPopupOpen = () => {
    const markerHtmlElement = markerRef.current ? markerRef.current.leafletElement.getElement() : null

    setIsOpen(true)
    if (markerHtmlElement) {
      markerHtmlElement.className += ' active'
    }
  }
  const onPopupClose = () => {
    const markerHtmlElement = markerRef.current ? markerRef.current.leafletElement.getElement() : null

    setIsOpen(false)
    if (markerHtmlElement) {
      markerHtmlElement.className = markerHtmlElement.className.replace(' active', '')
    }
  }

  useEffect(() => {
    if (isOpen && !networkAssetDetails) {
      if (abortController.current instanceof AbortController) {
        abortController.current.abort()
      }

      abortController.current = new AbortController()

      setIsLoading(true)
      getNetworkAssetsForListItemCall({
        client,
        abortController: abortController.current,
        networkAssetId: networkAsset.assetId,
      })
        .then(result => {
          abortController.current = null
          setNetworkAssetDetails(result.networkAsset)
          setIsLoading(false)
        })
    } else if (!isOpen && abortController.current) {
      abortController.current.abort()
      abortController.current = null
    }

    return () => {
      if (abortController.current) {
        abortController.current.abort()
        abortController.current = null
      }
    }
  }, [isOpen])

  return (
    <Popup
      autoPan={false}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      className={'network-asset-popup'}
    >
      {isLoading && (<p>Loading...</p>)}
      {!isLoading && (
        <>
          {!!issues && (
            <IssuesSummaryBadgeComponent
              className="align-self-start"
              issue={issues.mostSevereIssue ? issues.mostSevereIssue : null}
              errors={issues.errorsCount}
              warnings={issues.warningsCount}
              information={issues.informationCount}
            />
          )}
          <h5 className="network-asset-id align-self-start">
            {networkAssetDetails.name}
          </h5>
          <i className="network-asset-readable-name text-wrap text-break">
            {networkAssetDetails.readableName || '-'}
          </i>
          <div className="network-asset-type">
            <span className="d-block">{`${t('v2/common/text:pressure_data_freshness')}: ${lastRecordedDataTime}`}</span>
            <span className="d-block">{t(`v2/network_assets/filters:options.${networkAsset.type}`)}</span>
          </div>
          <div>
            <div className="mt-3 d-flex flex-row">
              {mapAssetTypeToLogicalChannels(networkAssetDetails.type).map((channel, key) => (
                <NetworkAssetInstallationIconComponent
                  logicalChannel={`${channel}`}
                  id={`network-asset-popup-${networkAssetDetails.assetId}-${channel}-${key}`}
                  key={`network-asset-popup-${networkAssetDetails.assetId}-${channel}-${key}`}
                  device={networkAssetDetails.installedDevices.find(where => where.logicalChannel === channel) || null}
                  style={{ width: '1.2rem', height: '2rem' }}
                />
              ))}
            </div>
          </div>
          <div className="mt-3 d-flex flex-row align-items-center">
            <Button size="sm" className="text-light mr-1" color="primary" tag={Link} to={detailsPage}>
              <i className="fa fa-info mr-2" />
              <span>{t('v2/common/buttons:show_details')}</span>
            </Button>
            {authorizedUser.can(CAN_VIEW_ANALYSIS) && (
              <Button size="sm" className="text-dark" color="light" tag={Link} to={showDataLink}>
                <i className="fa fa-chart-area mr-2" />
                <span>{t('v2/common/buttons:show_data')}</span>
              </Button>
            )}
          </div>
        </>
      )}
    </Popup>
  )
}

export default compose(
  withTranslation([
    'v2/network_assets/common',
    'v2/common/text',
  ])
)(NetworkAssetMapPopupComponent)
