import React, { createContext } from 'react'
import { renderAbsolutePositionedLoading } from '../../../components/app_suspense_container'
import { AuthorizedUser } from '../../models/authorized-user'
import AuthorizationIdle from '../authorization/authorization_idle'
import { useAuthorizedUser } from './hooks/use_authorized_user.hook'

export const UserContext = createContext({
  authorizedUser: AuthorizedUser.initNull(),
  isLogged: () => false,
  logout: () => Promise.resolve(null),
  reloadAuthorizedUser: () => Promise.resolve(null),
})

export const UserProvider = ({ children }) => {
  const { isAuthorizing, isLogged, authorizedUser, logout, reloadAuthorizedUser } = useAuthorizedUser()

  if (isAuthorizing) {
    return renderAbsolutePositionedLoading()
  }

  return (
    <UserContext.Provider value={{ authorizedUser, isLogged, logout, reloadAuthorizedUser }}>
      <AuthorizationIdle>
        {children}
      </AuthorizationIdle>
    </UserContext.Provider>
  )
}

export const withUser = (Component) => (props) => (
  <UserContext.Consumer>
    {(contextValues) => (<Component {...props} {...contextValues} />)}
  </UserContext.Consumer>
)
