import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { NavLink } from 'react-router-dom'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import { Col, Row, Card } from 'reactstrap'

import routes from '@@src/routes'
import AppButton from '@@src/components/buttons/app_button'
import DeviceIssuesBadge from '@@src/components/badges/device_issues_badge'
import DeviceIconWithStatus from '@@src/components/icons/device_icon_with_status'
import { AsyncResult, mapAssetTypeToLogicalChannels } from '@@src/utils'
import { CAN_VIEW_ANALYSIS } from '../../../_v2/contexts/user/consts/permissions'
import { withUser } from '../../../_v2/contexts/user/user.context'

import styles from './index.css'

const noop = () => {}

class CurrentInstallationsSection extends React.PureComponent {
  static propTypes = {
    onInstall: PropTypes.func.isRequired,
    onUninstall: PropTypes.func.isRequired,
    networkAssetResult: PropTypes.instanceOf(AsyncResult).isRequired,
    onClickDeleteInstallation: PropTypes.func.isRequired,
    suggestedDeviceInstallations: PropTypes.array.isRequired,
  }

  static defaultProps = {
    onInstall: noop,
    onUninstall: noop,
    onInstallSuccess: noop,
    onUninstallSuccess: noop,
  }

  selectorCache = {
    onInstall: {},
    onUninstall: {},
    onInstallSuccess: {},
    onUninstallSuccess: {},
  }

  render() {
    const { t, networkAssetResult } = this.props
    return (
      <div className={styles['current-installations-section']}>
        <Card className={styles['current-installation-card']}>
          <h5 className={styles['current-installation-heading']}>
            {t('headings.current_installation')}
          </h5>

          <AsyncResultSwitch
            result={networkAssetResult}
            renderSuccessResult={this.renderSuccessResult}
          />
        </Card>
      </div>
    )
  }

  renderSuccessResult = ({ data: networkAsset }) => {
    const { t, suggestedDeviceInstallations } = this.props
    const availableChannelTypes = mapAssetTypeToLogicalChannels(
      networkAsset.assetType
    )

    return (
      <Row>
        {availableChannelTypes.map((channel) => {
          const installation = networkAsset.currentInstallations.find(
            (ins) => ins.channelMap.pressure_1 === channel
          )

          const suggestion = suggestedDeviceInstallations.find(
            ({ channel: insChannel }) => {
              return insChannel === channel
            }
          )

          return (
            <Col key={channel} md="6" name="installation-channel-section">
              <h6 className={styles['current-installation-channel-heading']}>
                {t(`common/text:text.asset_channel_${channel}`)}
              </h6>

              {installation
                ? this.renderChannelInfoWithInstallation({
                  channel,
                  installation,
                })
                : suggestion
                  ? this.renderChannelInfoWithSuggestedInstallation({
                    channel,
                    device: suggestion.device,
                  })
                  : this.renderChannelInfoWithoutInstallation({ channel })}
            </Col>
          )
        })}
      </Row>
    )
  }

  renderChannelInfoWithInstallation({ channel, installation }) {
    const { t, permissions, onClickDeleteInstallation, authorizedUser } = this.props
    const { device } = installation

    return (
      <div className={styles['current-installation-container']}>
        <div className={styles['current-installation-device-item']}>
          <DeviceIconWithStatus
            device={device}
            className={styles['device-icon']}
          />

          <div>
            <div className={styles['current-installation-serial-number-row']}>
              <NavLink
                to={routes.managementDevicesDetailPath(device.id)}
                className={styles['device-serial-number']}
              >
                {device.serialNumber}
              </NavLink>
              &nbsp;
              {Array.isArray(device.activeIssues) &&
              device.activeIssues.length > 0 &&
              !device.isDecommissioned ? (
                  <span
                    className={styles['current-installation-device-issues-badge']}
                  >
                    <DeviceIssuesBadge deviceIssues={device.activeIssues} />
                  </span>
                ) : null}
            </div>

            <p className={styles['current-installation-time-text']}>
              {t('text.long_readable_full_time', {
                time: installation.start,
              })}
            </p>

            <p name="installation-location" className={styles['location-text']}>
              <i className="far fa-map-marker-alt"></i>
              &nbsp;
              {device.lastKnownLocation.latitude.toFixed(6)}
              ,&nbsp;
              {device.lastKnownLocation.longitude.toFixed(6)}
            </p>
          </div>
        </div>

        <div className={styles['current-installation-actions']}>
          <AppButton
            name="uninstall-device-button"
            size="sm"
            color="secondary"
            disabled={
              !permissions.includes('can_edit_devices') ||
              !permissions.includes('can_edit_network_assets')
            }
            onClick={this.selectCallbackFor({
              action: 'onUninstall',
              channel,
              installation,
            })}
            className={styles['current-installation-button']}
          >
            {t('buttons.uninstall_device')}
          </AppButton>

          {authorizedUser.can(CAN_VIEW_ANALYSIS) && (
            <NavLink
              name={'show-data-link' + installation.uuid}
              to={routes.analysisPressurePath({
                d: [device.uuid],
              })}
              className={classnames(
                styles['current-installation-button'],
                'btn btn-sm btn-light'
              )}
            >
              <i className="fa fa-chart-area"></i>
            </NavLink>
          )}
          <AppButton
            name="delete-installation-button"
            size="sm"
            color="light"
            onClick={onClickDeleteInstallation(installation)}
            disabled={
              !permissions.includes('can_edit_devices') ||
              !permissions.includes('can_edit_network_assets')
            }
            className={styles['current-installation-button']}
          >
            <i className="far fa-trash-alt"></i>
          </AppButton>
        </div>
      </div>
    )
  }

  renderChannelInfoWithSuggestedInstallation({ channel, device }) {
    const { t, permissions } = this.props

    return (
      <div>
        <Card className={styles['suggested-installation']}>
          <DeviceIconWithStatus
            device={device}
            className={styles['device-icon']}
          />

          <div className={styles['suggested-device-info']}>
            <NavLink
              to={routes.managementDevicesDetailPath(device.id)}
              className={styles['device-serial-number']}
            >
              {device.serialNumber}
            </NavLink>

            <span className={styles['previously-installed-text']}>
              {t('text.previously_installed')}
            </span>
          </div>
        </Card>

        <div>
          <AppButton
            name="reinstall-device-here-button"
            size="sm"
            color="primary"
            disabled={
              !permissions.includes('can_edit_devices') ||
              !permissions.includes('can_edit_network_assets')
            }
            className={styles['current-installation-button']}
            onClick={this.selectCallbackFor({
              start: device.currentCommission.start,
              action: 'onInstall',
              device,
              channel,
            })}
          >
            {t('buttons.reinstall_device')}
          </AppButton>

          <AppButton
            size="sm"
            color="outline"
            disabled={
              !permissions.includes('can_edit_devices') ||
              !permissions.includes('can_edit_network_assets')
            }
            className={styles['current-installation-button']}
            onClick={this.selectCallbackFor({
              action: 'onInstall',
              channel,
            })}
          >
            <i className="far fa-plus"></i>
            &nbsp;
            {t('buttons.install_another')}
          </AppButton>
        </div>
      </div>
    )
  }

  renderChannelInfoWithoutInstallation({ channel }) {
    const { t, permissions } = this.props

    return (
      <AppButton
        size="sm"
        color="primary"
        name="install-device-button"
        disabled={
          !permissions.includes('can_edit_devices') ||
          !permissions.includes('can_edit_network_assets')
        }
        className={styles['current-installation-button']}
        onClick={this.selectCallbackFor({
          action: 'onInstall',
          channel,
        })}
      >
        <i className="far fa-plus"></i>
        &nbsp;
        {t('buttons.assign_device')}
      </AppButton>
    )
  }

  selectCallbackFor({ action, channel, ...rest }) {
    const selector =
      this.selectorCache[action][channel] ||
      createSelector(
        [get(action)],
        (callback) => () => callback({ ...rest, channel })
      )

    this.selectorCache[action][channel] = selector
    return selector(this.props)
  }
}

export default compose(
  withUser,
  withTranslation([
    'src/management_path/network_assets_path/network_asset_detail_page',
    'common/text',
  ])
)(CurrentInstallationsSection)
