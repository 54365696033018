import React from 'react'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'
import { compose } from 'redux'
import { NavLink } from 'react-router-dom'
import { partition } from 'lodash/fp/collection'
import { withTranslation } from 'react-i18next'
import { Col, Row, Card, Button } from 'reactstrap'

import routes from '@@src/routes'
import AppButton from '@@src/components/buttons/app_button'
import { CAN_VIEW_ANALYSIS } from '../../../_v2/contexts/user/consts/permissions'
import { withUser } from '../../../_v2/contexts/user/user.context'
import InstallationRow from './installation_row'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'

import styles from './index.css'

class CurrentCommissionsSection extends React.PureComponent {
  render() {
    const {
      t, permissions, commission, installations, className, onClickCommission,
      onClickInstall, onClickUninstallFor, oneClickReinstallInfo,
      onClickDeleteInstallation, authorizedUser
    } = this.props
    const { location } = commission
    const [activeInstallations, previousInstallations] = partition(
      i => i.isActive()
    )(installations)

    return (
      <Card
        key={commission.uuid}
        className={className}>
        <Row>
          {
            permissions.includes('can_edit_devices') ? null :
            <ReactTooltip
              effect="solid"
              place="bottom"/>
          }
          <Col sm="8">
            <h5>
              {t('headings.current_commission')}
            </h5>

            <p className={styles['commission-time-text']}>
              {
                commission.end ? (
                  <React.Fragment>
                    <span>
                      {t('text.long_readable_full_time', {
                        time: commission.start,
                      })}
                    </span>

                    &nbsp;
                    &rarr;
                    &nbsp;

                    <span>
                      {t('text.long_readable_full_time', {
                        time: commission.end,
                      })}
                    </span>
                  </React.Fragment>
                ) : (
                  t('text.long_readable_full_time', { time: commission.start })
                )
              }
            </p>

            <p className={styles['location-text']}
              name="device-commission-location"
              onClick={onClickCommission}>
              <i className="far fa-map-marker-alt"></i>
              &nbsp;
              {location.latitude.toFixed(6)},{location.longitude.toFixed(6)}
            </p>
          </Col>

          {authorizedUser.can(CAN_VIEW_ANALYSIS) && (
            <Col sm="4" className={styles['actions-column']}>
              <NavLink
                name={'show-data-link' +
                  commission.device.uuid + commission.uuid }
                to={routes.analysisPressurePath({
                  d: [commission.device.uuid],
                })}
                className={
                  classnames(styles.button,
                    'btn btn-primary', styles['info-button'])
                }>
                <i className="fa fa-chart-area"></i>
                &nbsp;
                {t('buttons.show_data')}
              </NavLink>
            </Col>
          )}
        </Row>

        <h6 className={styles['network-asset-heading']}>
          {t('headings.network_asset')}
        </h6>

        {
          activeInstallations.length ?
            activeInstallations.map(installation => (
              <Row key={installation.uuid}>
                <Col sm="12">
                  <div
                    className={styles['current-commission-asset']}>
                    <NetworkAssetIcon
                      className={styles['current-commission-asset-icon']}
                      networkAsset={installation.networkAsset}/>

                    <div>
                      <NavLink
                        to={routes.networkAssetsDetailPath(
                          installation.networkAsset.id,
                        )}>
                        {installation.networkAsset.assetId}
                      </NavLink>

                      &nbsp;({installation.translateAssetChannel(t, 1)})

                      {
                        installation.networkAsset.assetName ? (
                          <div>
                            <i>{installation.networkAsset.assetName}</i>
                          </div>
                        ) : null
                      }

                      <div className={styles['current-commission-asset-type']}>
                        {installation.networkAsset.translateAssetType(t)}
                      </div>
                    </div>
                  </div>

                  <p className={styles['commission-time-text']}>
                    {
                      !installation.isActive() ? (
                        <React.Fragment>
                          <span>
                            {t('text.long_readable_full_time', {
                              time: installation.start,
                            })}
                          </span>

                          &nbsp;
                          &rarr;
                          &nbsp;

                          <span>
                            {t('text.long_readable_full_time', {
                              time: installation.end,
                            })}
                          </span>
                        </React.Fragment>
                      ) : (
                        t('text.long_readable_full_time', {
                          time: installation.start,
                        })
                      )
                    }
                  </p>

                  <p className={styles['location-text']}
                    name="device-commission-location"
                    onClick={onClickCommission}>
                    <i className="far fa-map-marker-alt"></i>
                    &nbsp;
                    {installation.networkAsset.location.latitude
                      .toFixed(6)},
                    {installation.networkAsset.location.longitude
                      .toFixed(6)}
                  </p>

                  <div className={styles['current-commission-btn-group']}>
                    <span data-tip={t('common/text:permissions.disabled')}>
                      <Button
                        disabled={!permissions.includes('can_edit_devices')}
                        name="uninstall-button"
                        size="sm"
                        color="secondary"
                        onClick={onClickUninstallFor(installation)}
                        className={styles['installation-button']}>
                        {t('text.uninstall')}
                      </Button>
                    </span>

                    {
                      permissions.includes('can_edit_devices') ? null :
                      <ReactTooltip
                        effect="solid"
                        place="bottom"/>
                    }

                    <Button
                      name="delete-button"
                      size="sm"
                      color="light"
                      disabled={
                        !permissions.includes('can_edit_devices') ||
                          !permissions.includes('can_edit_network_assets')
                      }
                      onClick={onClickDeleteInstallation(installation)}>
                      <i className="far fa-trash-alt"></i>
                    </Button>
                  </div>
                </Col>
              </Row>
            )) : (
              <Row>
                <Col sm="12">
                  {
                    oneClickReinstallInfo && oneClickReinstallInfo.previousInstallation ? ( // eslint-disable-line max-len
                      this.renderAssetInstallationSuggestion(
                        oneClickReinstallInfo.previousInstallation,
                      )
                    ) : (
                      <span data-tip={t('common/text:permissions.disabled')}>
                        <Button
                          name="install-network-asset-button"
                          size="sm"
                          color="primary"
                          onClick={onClickInstall}
                          disabled={
                            !permissions.includes('can_edit_devices') ||
                              !permissions.includes('can_edit_network_assets')
                          }>
                          <i className="far fa-plus"></i>
                          &nbsp;
                          {t('buttons.install_network_asset')}
                        </Button>
                      </span>
                    )
                  }
                </Col>
              </Row>
            )
        }

        {
          previousInstallations.length ? (
            <React.Fragment>
              <div className={styles['separator-with-margin']}></div>

              <h6 className={styles['previous-installations-heading']}>
                {t('headings.previous_installations')}
              </h6>
              {
                previousInstallations.map(installation => (
                  <InstallationRow
                    key={installation.uuid}
                    installation={installation}
                    onClickDelete={
                      onClickDeleteInstallation(installation)
                    }
                    onClickCommission={onClickCommission}/>
                ))
              }
            </React.Fragment>
          ) : null
        }
      </Card>
    )
  }

  renderAssetInstallationSuggestion(installation) {
    const { t, onClickInstall, oneClickReinstallInfo } = this.props
    const { networkAsset } = installation

    return (
      <React.Fragment>
        <Card className={styles['suggested-installation-card']}>
          <div
            className={styles['suggested-installation-asset']}>
            <NetworkAssetIcon
              className={styles['current-commission-asset-icon']}
              networkAsset={networkAsset}/>

            <div>
              <NavLink
                to={routes.networkAssetsDetailPath(networkAsset.id)}>
                {networkAsset.assetId}
              </NavLink>

              &nbsp;({installation.translateAssetChannel(t, 1)})

              {
                networkAsset.assetName ? (
                  <div>
                    <i>{networkAsset.assetName}</i>
                  </div>
                ) : null
              }

              <div className={styles['current-commission-asset-type']}>
                {networkAsset.translateAssetType(t)}
              </div>
            </div>
          </div>

          <p className={styles['suggested-installation-text']}>
            {t('text.previously_installed')}
          </p>
        </Card>

        <AppButton
          name="reinstall-here-button"
          size="sm"
          color="primary"
          onClick={oneClickReinstallInfo.reinstall}
          className={styles['install-button']}>
          <i className="far fa-plus"></i>
          &nbsp;
          {t('buttons.reinstall_this_asset')}
        </AppButton>

        &nbsp;

        <Button
          name="install-another-network-asset-button"
          size="sm"
          color="light"
          onClick={onClickInstall}>
          <i className="far fa-plus"></i>
          &nbsp;
          {t('buttons.install_another_network_asset')}
        </Button>
      </React.Fragment>
    )
  }
}

export default compose(
  withUser,
  withTranslation([
    'src/management_path/devices_path/device_detail_page',
    'common/text',
  ])
)(CurrentCommissionsSection)
