import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Button, UncontrolledTooltip } from 'reactstrap'

import DevicesIconComponent from '../../../../../components/devices/devices_icon.component'
import NetworkAssetIconComponent from '../../../../../components/network_asset/network_asset_icon.component'
import IssuesSummaryBadgeComponent from '../../../../../components/issues/issues_summary_badge.component'

import { DevicesForListItem } from '../../../../../api/models/devices_for_list_item'
import { useTranslation } from 'react-i18next'

import routes from '../../../../../../routes'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import { CAN_VIEW_ANALYSIS } from '../../../../../contexts/user/consts/permissions'
import { useAuthorizedUser } from '../../../../../contexts/user/hooks/use_authorized_user.hook'
import DevicesListItemInfo from './devices_list_item_info.component'

const DevicesListItem = ({
  device,
  intervalService = {},
  openInstallDeviceModal = () => null,
  isLast = false,
}) => {
  const issuesStats = device.getMostSeverityIssueWithOtherIssuesCount()
  const showDataLink = routes.analysisPressurePath({ d: [device.getDataLinkId()] })
  const detailsLink = routes.managementDevicesDetailPath(+device.deviceId)
  const { authorizedUser } = useAuthorizedUser()

  const { t } = useTranslation(['v2/common/text', 'v2/network_assets/filters'])
  const handleOpenInstallDeviceModal = () => {
    openInstallDeviceModal(device)
  }

  return (
    <div className={classnames('w-100 d-flex flex-row justify-content-start', {
      'border-bottom mb-2': !isLast,
    })}>
      <DevicesIconComponent type={device.getTableIconName()} />
      <div className="w-100">
        <div className="d-flex flex-row">
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex flex-row">
              <span>{device.serialNumber}</span>
              {!!issuesStats && (
                <IssuesSummaryBadgeComponent
                  className="ml-2 align-self-center"
                  issue={issuesStats.mostSevereIssue}
                  errors={issuesStats.errorsCount}
                  warnings={issuesStats.warningsCount}
                  information={issuesStats.informationCount}
                />
              )}
            </div>
          </div>
          <div className="ml-auto d-flex align-items-start">
            <div className="mx-1">
              {
                device.isInstalled &&
                <>
                  <NavLink
                    id={'asset' + device.installation.assetId}
                    to={routes.networkAssetsDetailPath(device.installation.assetId)}>
                    <NetworkAssetIconComponent
                      type={device.installation.assetType}
                      style={{ width: '2rem', height: '2rem' }} />
                  </NavLink>
                  <UncontrolledTooltip
                    target={'asset' + device.installation.assetId}
                    placement="left">
                    <span>
                      {device.installation.assetName}&nbsp;
                      ({t(`v2/network_assets/filters:options.${device.installation.assetType}`)})
                    </span>
                  </UncontrolledTooltip>
                </>
              }
            </div>
            <Button
              className="mx-1"
              color="primary"
              disabled={device.isInstalled || !device.isCommissioned}
              onClick={handleOpenInstallDeviceModal}>
              {t('install')}
            </Button>
            {authorizedUser && authorizedUser.can(CAN_VIEW_ANALYSIS) && (
              <Button className="mx-1" color="light" tag={Link} to={showDataLink}>
                <i className="fa fa-chart-area" />
              </Button>
            )}
            <Button className="mx-1" color="light" tag={Link} to={detailsLink}>
              <i className="fa fa-info" />
            </Button>
          </div>
        </div>
        <DevicesListItemInfo
          device={device}
          subscribe={intervalService.subscribe}
          unsubscribe={intervalService.unsubscribe} />
      </div>
    </div>
  )
}

DevicesListItem.propTypes = {
  device: PropTypes.instanceOf(DevicesForListItem),
  openInstallDeviceModal: PropTypes.func.isRequired,
  isLast: PropTypes.bool.isRequired,
}

export default DevicesListItem
