import { useContext, useEffect, useRef, useState } from 'react'
import { AppConfigContext } from '../app_config/app-config.context'
import { UserContext } from '../user/user.context'

const AUTHORIZATION_IDLE_INTERVAL = 5000

const AuthorizationIdle = function({ children }) {
  const interval = useRef(null)
  const { authorizedUser, isLogged, logout } = useContext(UserContext)
  const { appConfig: { identityProviderConfig } } = useContext(AppConfigContext)
  const [idleTime, setIdleTime] = useState(0)

  useEffect(() => {
    if (!isLogged()) {
      return
    }

    const clearIdleTime = () => setIdleTime(0)

    document.addEventListener('touchstart', clearIdleTime)
    document.addEventListener('mousemove', clearIdleTime)
    document.addEventListener('click', clearIdleTime)
    document.addEventListener('keydown', clearIdleTime)

    interval.current = setInterval(() => {
      setIdleTime(idleTime + AUTHORIZATION_IDLE_INTERVAL)
    }, AUTHORIZATION_IDLE_INTERVAL)

    return () => {
      document.removeEventListener('touchstart', clearIdleTime)
      document.removeEventListener('mousemove', clearIdleTime)
      document.removeEventListener('click', clearIdleTime)
      document.removeEventListener('keydown', clearIdleTime)
      clearInterval(interval.current)
    }
  }, [isLogged, idleTime, setIdleTime])

  useEffect(() => {
    if (isLogged() && idleTime > identityProviderConfig.sessionTimeout) {
      logout()
    }
  }, [idleTime, identityProviderConfig, authorizedUser, isLogged, logout])
  return children
}

export default AuthorizationIdle
