import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { isNil } from 'lodash/lang'
import { Popup } from 'react-leaflet'

import Device from '@@src/api/presenters/device'
import routes from '@@src/routes'
import NetworkAsset from '@@src/api/presenters/network_asset'
import DeviceIconWithStatus from '@@src/components/icons/device_icon_with_status'
import { mapAssetTypeToLogicalChannels } from '@@src/utils'
import StreetViewLink from '@@src/components/maps/markers/street_view_link'
import DeviceIssueSeverityBadge from '@@src/components/badges/device_issue_severity_badge'
import { NetworkAssetMarkerTranslationContext } from '@@src/components/maps/markers/network_asset_marker_translation_provider'
import { compose } from 'redux'
import { CAN_VIEW_ANALYSIS } from '../../../_v2/contexts/user/consts/permissions'
import { useAuthorizedUser } from '../../../_v2/contexts/user/hooks/use_authorized_user.hook'
import { withUser } from '../../../_v2/contexts/user/user.context'

import styles from './network_asset_popup.css'

const NO_DEVICE = Device.from({})

class NetworkAssetPopup extends React.PureComponent {
  static defaultProps = {
    showDetailsLink: true,
    showAddLink: false,
    showDataLink: true,
    selected: false,
    isLimitReached: false,
    showInstallations: true,
  }

  static propTypes = {
    showDetailsLink: PropTypes.bool.isRequired,
    showAddLink: PropTypes.bool,
    showDataLink: PropTypes.bool,
    isLimitReached: PropTypes.bool,
    networkAsset: PropTypes.instanceOf(NetworkAsset).isRequired,
    addToSelected: PropTypes.func,
    removeFromSelected: PropTypes.func,
    selected: PropTypes.bool,
    showInstallations: PropTypes.bool.isRequired,
    googleMaps: PropTypes.object,
  }

  render() {
    const {
      networkAsset,
      showDetailsLink,
      removeFromSelected,
      showAddLink,
      addToSelected,
      selected,
      showDataLink,
      isLimitReached,
      showInstallations,
      googleMaps,
      authorizedUser
    } = this.props
    const mostSevereDeviceIssue = networkAsset.mostSevereDeviceIssue
    const shouldShowDataLink = showDataLink && authorizedUser.can(CAN_VIEW_ANALYSIS)

    return (
      <Popup>
        <NetworkAssetMarkerTranslationContext.Consumer>
          {(t) => (
            <div className="p-3">
              <div className="d-flex align-items-center flex-wrap">
                <h5 className={styles['network-asset-id']}>
                  {networkAsset.assetId}
                </h5>
                {mostSevereDeviceIssue ? (
                  <DeviceIssueSeverityBadge
                    issue={mostSevereDeviceIssue}
                    className={styles['device-issue-badge']}
                  />
                ) : null}
              </div>

              {networkAsset.assetName ? (
                <div>
                  <i>{networkAsset.assetName}</i>
                </div>
              ) : null}

              <div className={styles['network-asset-type']}>
                {t(`text.${networkAsset.assetType}`)}
              </div>

              {!isNil(googleMaps) && networkAsset.location ? (
                <StreetViewLink
                  t={t}
                  googleMaps={googleMaps}
                  latitude={networkAsset.location.latitude}
                  longitude={networkAsset.location.longitude}
                />
              ) : null}

              {showInstallations ? (
                <div className="mt-3 d-flex">
                  {mapAssetTypeToLogicalChannels(networkAsset.assetType).map(
                    (channel) => {
                      const installation =
                        networkAsset.installationForChannel(channel)
                      const device = installation
                        ? installation.device
                        : NO_DEVICE
                      const domId = `na-${networkAsset.id}-${channel}`

                      return (
                        <div key={channel}>
                          <DeviceIconWithStatus
                            id={domId}
                            device={device}
                            className={styles['device-icon']}
                          />

                          {installation && document.getElementById(domId) ? (
                            <UncontrolledTooltip
                              target={domId}
                              // the following line is necessary to fix this issue:
                              // https://github.com/reactstrap/reactstrap/issues/1482#issuecomment-498747688
                              modifiers={{ flip: { enabled: false } }}
                              placement="top"
                              className={styles['device-serial-number-tooltip']}
                            >
                              {t(
                                /* eslint-disable-next-line max-len */
                                `common/text:text.asset_channel_${channel}`
                              )}
                              : {device.serialNumber}
                            </UncontrolledTooltip>
                          ) : null}
                        </div>
                      )
                    }
                  )}
                </div>
              ) : null}

              <div className="mt-3 d-flex flex-row">
                {showDetailsLink === true ? (
                  <Button
                    size="sm"
                    className="mr-2 text-white"
                    color="primary"
                    tag={Link}
                    to={routes.networkAssetsDetailPath(networkAsset.id)}
                  >
                    <i className="fa fa-info mr-2" />
                    {t('buttons.show_details')}
                  </Button>
                ) : null}

                {showAddLink === true ? (
                  selected === true ? (
                    <Button
                      size="sm"
                      name="remove-from-selected-sources"
                      color="secondary"
                      outline
                      onClick={() => removeFromSelected(networkAsset)}
                    >
                      <i className="fa fa-times mr-2" />
                      {t('buttons.remove')}
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      name="add-to-selected-sources"
                      color="primary"
                      disabled={isLimitReached}
                      onClick={() => addToSelected(networkAsset)}
                    >
                      <i className="fa fa-plus mr-2" />
                      {t('buttons.select')}
                    </Button>
                  )
                ) : null}

                {shouldShowDataLink ? (
                  <Button
                    size="sm"
                    className="text-dark"
                    color="light"
                    name="show-data-link"
                    tag={Link}
                    to={routes.analysisPressurePath({
                      d: [networkAsset.uuid],
                    })}
                  >
                    <i className="fa fa-chart-area mr-2" />
                    {t('buttons.show_data')}
                  </Button>
                ) : null}
              </div>
            </div>
          )}
        </NetworkAssetMarkerTranslationContext.Consumer>
      </Popup>
    )
  }
}

export default compose(
  withUser
)(NetworkAssetPopup)
