import { useCallback, useEffect, useState } from 'react'
import { apiUrl, tenant } from '../../../../config'

export class AppConfig {
  /**
   * @property {string} tenantShortcode
   * @property {Object} tenantSettings
   * @property {string} tenantSettings.language
   * @property {Object} identityProviderConfig
   * @property {'keycloak' | 'aws-cognito'} identityProviderConfig.type
   * @property {Object} identityProviderConfig.settings
   * @property {number} identityProviderConfig.sessionTimeout
   * @param config
   */
  constructor(config) {
    this.tenantShortcode = config.tenantShortcode
    this.tenantSettings = config.tenantSettings
    this.identityProviderConfig = config.identityProviderConfig
  }

  static initNullInstance() {
    return new AppConfig({})
  }
}

/**
 * @return {{isAppConfigLoading:boolean,appConfig:AppConfig|null,reloadAppConfig:()=>Promise<void>}}
 */
export const useAppConfig = () => {
  const [isAppConfigLoading, setIsAppConfigLoading] = useState(true)
  const [appConfig, setAppConfig] = useState(null)

  const reloadAppConfig = useCallback(async () => {
    setIsAppConfigLoading(true)

    const response = await fetch(`${apiUrl}/v1/rest/${tenant}/services`)
    const appConfig = await response.json()

    setIsAppConfigLoading(false)

    if (!response.ok || response.status !== 200) {
      throw new Error(`Cannot load app config for tenant ${tenant}`)
    }

    setAppConfig(appConfig)
  }, [setAppConfig, setIsAppConfigLoading])

  useEffect(() => {
    reloadAppConfig().then()
  }, [reloadAppConfig])

  return { isAppConfigLoading, appConfig, reloadAppConfig }
}
